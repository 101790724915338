import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { blobToBase64, sortItems } from "../../common/utilities/utilities"
import { BorderLinearProgress } from "../../common/utilities/customMaterialUI"
import ParsoIco from "../../common/components/Svgs/ParsoIco"
import { api } from '../../api/api'
import { Types } from '../../state/actionTypes'
import { _dispatch as dispatch, defaultCatch, defaultSuccess } from '../../state/actions'
import './LoadPage.sass'
import { requestForToken } from '../../config/firebase'

function LoadPage(props) {
  const {
    email,
    dispatch,
    enabledWorkplace,
    enabledVisits,
    enabledDesk,
    enabledRoom,
    defaultCatch,
    defaultSuccess
  } = props

  const navigate = useNavigate()

  const updateFirebaseToken = firebase_token => {
    dispatch({
      type: Types.SET_FIREBASE_TOKEN,
      payload: { firebase_token }
    })

    const data = {
      user: {
        email,
        firebase_token,
        os_device_type: "ANDROID",
      }
    }

    api.put('/profile', data)
      .then(defaultSuccess)
      .catch(error => defaultCatch(error, navigate, fetchCompanies))
  }

  const fetchCompanies = () => {
    api.get('/companies')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const companies = data.companies
        dispatch({
          type: Types.SET_COMPANIES,
          payload: companies
        })
        navigate('/')
      })
      .catch(error => defaultCatch(error, navigate, fetchCompanies))
  }

  const fetchSites = () => {
    const desks = enabledDesk || enabledVisits;
    const rooms = enabledRoom;

    if (desks || rooms) {
      const params = { desks, rooms };
      api.get('/workplace/sites', { params })
        .then(defaultSuccess)
        .then(({ data }) => {
          if (!data.success) return;

          dispatch({
            type: Types.SET_SITES,
            payload: sortItems(data.sites || [])
          });

          enabledVisits ? fetchCompanies() : navigate('/');
        })
        .catch(error => defaultCatch(error, navigate, fetchSites));
    } else {
      enabledVisits ? fetchCompanies() : navigate('/');
    }
  };

  const fetchLogo = () => {
    api.get('logo', { responseType: "blob" })
      .then(({ data }) => {
        if (data.type !== 'text/html') {
          blobToBase64(data)
            .then(base64Image => {
              dispatch({
                type: Types.SET_LOGO,
                payload: { logo: base64Image }
              })
            })
            .catch(error => console.error('Error al convertir Blob a Base64:', error));
        }
        if (enabledWorkplace) fetchSites()
        else if (enabledVisits) fetchCompanies()
        else navigate('/')
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchLogo, [])

  useEffect(() => {
    const getToken = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const token = await requestForToken();
          if (token) updateFirebaseToken(token);
          else console.log('No registration token available.');
        } else console.error('Error permission:', permission);
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="LoadPage">
      <div className="box-loading">
        <ParsoIco width={75} />
        <BorderLinearProgress />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    email: state.profile.email,
    enabledWorkplace: state.profile.enabled_workplace,
    enabledRoom: state.profile.enabled_room_reservations,
    enabledDesk: state.profile.company.enabled_desk_reservations,
    enabledVisits: state.profile.enabled_visits,
  }
}

const mapDispatchToProps = {
  dispatch,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadPage)
