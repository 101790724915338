import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
  Button,
  DialogActions,
  DialogTitle,
  Stack
} from '@mui/material'

import {
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
  West as WestIcon
} from '@mui/icons-material'


import { useTranslation } from 'react-i18next'

import './ScheduleSelector.sass'
import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector'
import { Dot } from '../Svgs'
import { getTimeDuration } from '../../utilities/FormatDate'
import MessageBox from '../MessageBox/MessageBox'
import { api } from '../../../api/api'
import { connect } from 'react-redux'

import {
  setLoading,
  defaultCatch,
  defaultSuccess,
  _dispatch as dispatch
} from '../../../state/actions'

const ScheduleSelector = props => {
  const {
    open,
    isToday = false,
    handleClose,
    prevReservations,
    onContinue,
    isDateAvailable,
    handleBack,
    workStartTime,
    workEndTime,
    maxReservationTime,
    setLoading,
    defaultCatch,
    defaultSuccess,
    graphAndOutlook,
    date
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [hasCollision, setHasCollision] = useState(false)
  const [exceedsMaxTime, setExceedsMaxTime] = useState(false)
  const [entryTime, setEntryTime] = useState()
  const [exitTime, setExitTime] = useState()
  const [showOwnCalendar, setShowOwnCalendar] = useState(graphAndOutlook)
  const [ownerEvents, setOwnerEvents] = useState([])

  const cleanEvents = (events = []) => {
    return events.map(e => {
      return { 
        ...e,
        entry_time: (e.start_time.toString().replace(' ', 'T')),
        exit_time: (e.end_time.toString().replace(' ', 'T'))
      }
    })

  }

  useEffect(() => {
    if (!graphAndOutlook) return
    setLoading(true)
    api.get('/graph/events', { params: {date } })
    .then(defaultSuccess)
      .then(({ data }) => {
        setOwnerEvents(cleanEvents(data.events).reverse())
      }).catch(error => defaultCatch(error, navigate))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const onChange = useCallback(params => {
    const { entry_time, exit_time, hasCollision } = params
    setHasCollision(hasCollision)

    if (entry_time && exit_time && maxReservationTime !== 0) {
      const duration = getTimeDuration(entry_time, exit_time);
      setExceedsMaxTime(duration > maxReservationTime);
    } else {
      setExceedsMaxTime(false);
    }

    if (props.onChange) {
      props.onChange(params)
      return
    }

    setEntryTime(entry_time)
    setExitTime(exit_time)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxReservationTime, props])

  return (
    <Dialog
      className="dialog-detail schedule-selector"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle>
        <Box className='schedule-selector-header'>
          {isDateAvailable ?
            <>
              <h2>{t('Select the time you would like to book')}</h2>
              <IconButton onClick={handleClose} >
                <CloseIcon />
              </IconButton>
            </>
            :
            <>
              <IconButton onClick={handleBack} >
                <WestIcon />
              </IconButton>
              <h2>{t('View who booked the room')}</h2>
            </>
          }
        </Box>
      </DialogTitle>
      <DialogContent className="dialog-schedule-selector-content">
        <Stack spacing={2}  direction="row" justifyContent="space-between"  alignItems='center'>
          <Stack spacing={2}  justifyContent="center" className='schedule-selector-legend-container'>
            <Box className='schedule-selector-legend unavailable'>
              <Dot />
              {t('Unavailable Hours')}
            </Box>
            <Box className='schedule-selector-legend available'>
              <Dot />
              {t('Available Hours')}
            </Box>
            <Box className={`schedule-selector-legend my-calendar ${showOwnCalendar ? '': 'hide'}`}>
              <Dot />
              {t('My calendar')}
            </Box>
          </Stack>
          { graphAndOutlook &&
            <Button
              variant="text"
              startIcon={showOwnCalendar ? <VisibilityOffIcon />: <VisibilityIcon />}
              onClick={() => setShowOwnCalendar(prevShowOwnCalendar => !prevShowOwnCalendar)}
            >
              {t('My calendar')}
            </Button>
          }
        </Stack>
        <TimeRangeSelector
          onChange={onChange}
          ownerEvents={ownerEvents}
          prevReservations={prevReservations}
          availableDate={isDateAvailable}
          entryTime={props.entryTime ? props.entryTime : entryTime}
          exitTime={props.exitTime ? props.exitTime : exitTime}
          isToday={isToday}
          workStartTime={workStartTime}
          workEndTime={workEndTime}
          maxReservationTime={maxReservationTime}
          showOwnCalendar={showOwnCalendar}
        />
        {exceedsMaxTime &&
          <MessageBox 
            type='error'
            message={t('Select a maximum time range of [X] minutes', { x: maxReservationTime })}
          />
        }
      </DialogContent>
      {isDateAvailable &&
        <DialogActions>
          <Button
            disabled={hasCollision || exceedsMaxTime}
            className='book-room-button'
            variant='contained'
            onClick={() => onContinue({ entryTime, exitTime })}
          >
            {t('save')}
          </Button>
        </DialogActions>}
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    graphAndOutlook: state.profile.graph_user_id && state.profile.company.enabled_outlook_integration,
  }
}

const mapDispatchToProps = {
  setLoading,
  defaultCatch,
  defaultSuccess,
  dispatch
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSelector)

