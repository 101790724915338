import React, { useCallback } from 'react'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  Hidden,
  Card,
  CardContent,
  Button,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'

import {
  AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
  LocationCity as LocationCityIcon,
  DirectionsCar as DirectionsCarIcon,
  Notes as NotesIcon,
  WatchLater as WatchLaterIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Event as EventIcon,
  Place as PlaceIcon,
  Groups as GroupsIcon,
  Login as MarkEntryIcon,
  Logout as MarkExitIcon,
  ListAlt as ListAltIcon,
  Restaurant as RestaurantIcon,
  Key as KeyIcon
} from '@mui/icons-material'

import { useNavigate } from 'react-router-dom'

import { Arrow } from '../Svgs'
import {
  defaultFunction,
  getPlace,
  stopEvent,
} from '../../utilities/utilities'

import {
  formatDateObj,
  formatDateISOShort,
  formatTime,
  getFormateRangeSessionTime,
  formatDateMed,
  isToday,
  getTime,
  getDiffInMinutesFromNow
} from '../../utilities/FormatDate'

import {
  PARKING_RESERVATION,
  DESK_RESERVATION,
  ROOM_RESERVATION,
  DESK_ASSIGNATION,
  PARKING_ASSIGNATION,
  CAFETERIA_RESERVATION,
  APPROVED,
  REJECTED,
  PENDING
} from '../../../variables'

import {
  reasonWorkplaceI18n
} from '../../utilities/Dictionary'

import './CardWrapper.sass'
import StatusChip from '../StatusChip/StatusChip'
import CustomWorkplaceIcons from '../../utilities/CustomWorkplaceIcons'

const iconSx = { color: '#8C8CA1', fontSize: 15 }
function CardWrapper(props) {

  const {
    items = [],
    askForRoomReservationNotes,
    askForParkingReservationEntryTime,
    askForParkingReservationVehicle,
    askForDeskReservationEntryTime,
    openItem,
    // openDirections,
    removeItem,
    workplaceEnabledSymptomAssessment,
    enabledDeskReservationManualCheckin,
    enabledParkingReservationManualCheckin,
    enabledRoomReservationManualCheckin,
    showDeskReservationReason,
    enabledMultiplesParkingReservations,
    markArrival = defaultFunction,
    refNode,
    cafeteriaMinTimeForCancelOrder,
    workplaceCustomLabel,
    workplaceCustomIconIndex,
    enabledWorkplaceCustomIcon,
    enabledAccessControlQr
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))


  const goHealtForm = useCallback(e => {
    stopEvent(e)
    navigate('/healt-form')
  }, [navigate])

  const assistanceCompleted = useCallback(item => {
    if (isToday(item.reservation_date) && item?.session?.end_time) {
      return (
        <li title={getFormateRangeSessionTime(item.session)}>
          <CheckCircleIcon sx={iconSx} className="SUCCESS" />{t("Assistance completed")}
        </li>
      )
    }
  }, [t])

  const getParkingTime = useCallback(item => {
    const time = enabledMultiplesParkingReservations ?
      getTime(item) :
      formatTime(item.entry_time)

    return (
      <li>
        <WatchLaterIcon
          sx={iconSx} />
        {time}
      </li>
    )
  }, [enabledMultiplesParkingReservations])

  const getParkingItems = useCallback(item => {

    const {
      parking_space = {}
    } = item

    return (
      <ul className='item-list'>
        <li><StatusChip status={item.status} /></li>
        <li><PlaceIcon sx={iconSx} /><strong>{parking_space.parking_space_label}, {parking_space.parking_lot_name}</strong></li>
        {askForParkingReservationVehicle && <li><DirectionsCarIcon sx={iconSx} />{item?.vehicle?.license_plate}</li>}
        <li><EventIcon sx={iconSx} />{formatDateISOShort(item.reservation_date)}</li>
        {askForParkingReservationEntryTime && getParkingTime(item)}
        {assistanceCompleted(item)}
        {/* <li>
          <Link
            href="#"
            color="inherit"
            onClick={e => openDirections(e, item)}>
            {t('how to get')}
          </Link>
        </li> */}
      </ul>
    )
  }, [askForParkingReservationEntryTime, askForParkingReservationVehicle, assistanceCompleted, getParkingTime])

  const getParkingAssignationItems = useCallback(item => {

    const {
      parking_space = {}
    } = item

    return (
      <ul className='item-list'>
        <li><PlaceIcon sx={iconSx} /><strong>{parking_space.parking_space_label}, {parking_space.parking_lot_name}</strong></li>
        <li><EventIcon sx={iconSx} />{formatDateISOShort(item.reservation_date)}</li>
        {assistanceCompleted(item)}
      </ul>
    )
  }, [assistanceCompleted])


  const getWorkplaceItems = useCallback(item => {
    return (
      <ul className='item-list'>
        <li><StatusChip status={item.status} /></li>
        <li title={getPlace(item)}><LocationCityIcon sx={iconSx} /><strong>{getPlace(item)}</strong></li>
        <li>
          {enabledWorkplaceCustomIcon && workplaceCustomIconIndex !== undefined?
            <CustomWorkplaceIcons
              index={workplaceCustomIconIndex}
              sx={iconSx}
              color={"#8C8CA1"}
            />
            :
            <AirlineSeatReclineNormalIcon sx={iconSx} />
          }
          {item.desk.name}
        </li>
        {askForDeskReservationEntryTime && <li><WatchLaterIcon sx={iconSx} />{getTime(item, t)}</li>}
        {assistanceCompleted(item)}
        {
          workplaceEnabledSymptomAssessment &&
          isToday(item.reservation_date) &&
          <li>
            {
              item.has_symptom_assessment ?
                <><CheckCircleIcon sx={iconSx} className="SUCCESS" />{t("complete health check")} </> :
                <Button className="go-healt-form" onClick={goHealtForm}><ErrorIcon sx={iconSx} className="DANGER" />{t("incomplete health check")} </Button>
            }
          </li>
        }
      </ul>
    )
  }, [askForDeskReservationEntryTime, assistanceCompleted, goHealtForm, t, workplaceEnabledSymptomAssessment, workplaceCustomIconIndex, enabledWorkplaceCustomIcon])

  const getWorkplaceAssignationItems = useCallback(item => {
    return (
      <ul className='item-list'>
        <li title={getPlace(item)}><LocationCityIcon sx={iconSx} /><strong>{getPlace(item)}</strong></li>
        <li><AirlineSeatReclineNormalIcon sx={iconSx} />{item.desk.name}</li>
        <li><WatchLaterIcon sx={iconSx} />{getTime(item, t)}</li>
        {assistanceCompleted(item)}
        {
          workplaceEnabledSymptomAssessment &&
          isToday(item.reservation_date) &&
          <li>
            {
              item.has_symptom_assessment ?
                <><CheckCircleIcon sx={iconSx} className="SUCCESS" />{t("complete health check")} </> :
                <Button className="go-healt-form" onClick={goHealtForm}><ErrorIcon sx={iconSx} className="DANGER" />{t("incomplete health check")} </Button>
            }
          </li>
        }
      </ul>
    )
  }, [assistanceCompleted, goHealtForm, t, workplaceEnabledSymptomAssessment])


  const getRoomItems = useCallback(item => {
    return (
      <ul className='item-list'>
        <li><StatusChip status={item.status} /></li>
        <li title={getPlace(item)}><LocationCityIcon sx={iconSx} /><strong>{getPlace(item)}</strong></li>
        <li><GroupsIcon sx={iconSx} />{item.room.name}</li>
        <li><WatchLaterIcon sx={iconSx} />{getTime(item, t)}</li>
        {askForRoomReservationNotes && item.notes && <li><NotesIcon sx={iconSx} />{item.notes}</li>}
        {assistanceCompleted(item)}
      </ul>
    )
  }, [askForRoomReservationNotes, assistanceCompleted, t])

  const getCafeteriaItems = useCallback(item => {
    const diffInMinutes = getDiffInMinutesFromNow(item.order_time)

    return (
      <ul className='item-list'>
        {item.status === REJECTED &&
          <li>
            <StatusChip
              status={item.status}
              label={t("Reservation deleted by the administrator")}
            />
          </li>
        }
        {item.status === PENDING && item.order_time &&
          isToday(item.order_date) && diffInMinutes > cafeteriaMinTimeForCancelOrder &&
          <StatusChip
            label={t("Expired reservation")}
          />
        }
        <li><PlaceIcon sx={iconSx} />{item.site.name}</li>
        {!!item.order_time && <li><WatchLaterIcon sx={iconSx} />{item.order_time}</li>}
        <li className='order-detail-list'>
          <ListAltIcon sx={iconSx} />
          <ul>
            {item.meals?.map((meal, index) => {
              return (
                <li key={`${index}-${meal.id}`}>
                  <span>{meal.name}</span>
                </li>
              )
            })}
          </ul>
        </li>
      </ul>
    )

  }, [t, cafeteriaMinTimeForCancelOrder])

  const showReason = useCallback(() => (
    showDeskReservationReason || askForDeskReservationEntryTime) ? 'width-reason' : '',
    [showDeskReservationReason, askForDeskReservationEntryTime]
  )

  const isParking = item => item.type === PARKING_RESERVATION || item.type === PARKING_ASSIGNATION
  const isDesk = item => item.type === DESK_RESERVATION || item.type === DESK_ASSIGNATION
  const isRoom = item => item.type === ROOM_RESERVATION
  const isAssignation = item => item.type === DESK_ASSIGNATION || item.type === PARKING_ASSIGNATION
  const isCafeteriaOrder = item => item.type === CAFETERIA_RESERVATION


  const getTitle = useCallback(item => {
    if (isParking(item)) return t('parking')
    if (isDesk(item)) return workplaceCustomLabel ? workplaceCustomLabel : t('workplace')
    if (isRoom(item)) return t('room')
    if (isCafeteriaOrder(item)) return t('Cafeteria')
  }, [t, workplaceCustomLabel])

  const getMainIcon = useCallback(item => {
    if (isParking(item)) return <DirectionsCarIcon width={22} />
    if (isDesk(item)) return (
      enabledWorkplaceCustomIcon && workplaceCustomIconIndex !== undefined ?
        <CustomWorkplaceIcons
          index={workplaceCustomIconIndex}
          width={22}
        />
        :
        <AirlineSeatReclineNormalIcon width={22} />
    )
    if (isRoom(item)) return <GroupsIcon width={22} />
    if (isCafeteriaOrder(item)) return <RestaurantIcon width={22} />
  }, [enabledWorkplaceCustomIcon, workplaceCustomIconIndex])

  const getForm = useCallback(item => {
    if (item.type === PARKING_RESERVATION) return getParkingItems(item)
    if (item.type === DESK_RESERVATION) return getWorkplaceItems(item)
    if (item.type === ROOM_RESERVATION) return getRoomItems(item)
    if (item.type === PARKING_ASSIGNATION) return getParkingAssignationItems(item)
    if (item.type === DESK_ASSIGNATION) return getWorkplaceAssignationItems(item)
    if (item.type === CAFETERIA_RESERVATION) return getCafeteriaItems(item)
  }, [getParkingAssignationItems, getParkingItems, getRoomItems, getWorkplaceAssignationItems, getWorkplaceItems, getCafeteriaItems])

  const getCheckinButton = useCallback(item => {
    const isManualCheckinEnabled =
      (isParking(item) && enabledParkingReservationManualCheckin) ||
      (isDesk(item) && enabledDeskReservationManualCheckin) ||
      (isRoom(item) && enabledRoomReservationManualCheckin)

    if (
      isManualCheckinEnabled &&
      isToday(item.reservation_date) &&
      !item?.session?.end_time &&
      item.status === APPROVED
    ) {
      return (
        item?.session?.start_time ?
          <Button
            onClick={() => markArrival(item)}
            className='mark-status-button outlined'
            variant='outlined'
            startIcon={<MarkExitIcon />}
          >
            {t('Check Out')}
          </Button > :
          <Button
            onClick={() => markArrival(item)}
            className='mark-status-button contained'
            variant='contained'
            startIcon={<MarkEntryIcon />}
          >
            {t('Mark arrival')}
          </Button>
      )
    }
    return null

  }, [enabledDeskReservationManualCheckin, enabledParkingReservationManualCheckin, enabledRoomReservationManualCheckin, markArrival, t])

  const onClickItem = useCallback(item => {
    if (isAssignation(item)) return
    openItem(item)
  }, [openItem])

  const getCancelButton = useCallback(item => {
    let diffInMinutes;
    if (item.type === CAFETERIA_RESERVATION && item.order_time) {
      diffInMinutes = getDiffInMinutesFromNow(item.order_time)
    }

    if (item.type === DESK_ASSIGNATION) return
    if (item.order_time && isToday(item.order_date) && (diffInMinutes !== undefined && diffInMinutes > -cafeteriaMinTimeForCancelOrder)) return

    return (
      <Button
        color='error'
        onClick={() => removeItem(item)}>
        {t('cancel reservation')}
      </Button>
    )

  }, [removeItem, t, cafeteriaMinTimeForCancelOrder])

  const getQRCard = useCallback(() => {
    return (
      <Card className="cp-card QR" onClick={() => navigate('/qr')}>
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={2}
          direction={isLarge ? 'column' : 'row'}
        >
          <KeyIcon />
          <h3>{t('Show QR access')}</h3>
        </Stack>
      </Card>
    )
  }, [isLarge, navigate, t])


  const getCard = useCallback((item, key) => {
    return (
      <Card className={`cp-card ${item.status} ${showReason()}`} key={`${key}-${item.id}`}>
        <CardContent onClick={() => onClickItem(item)}>
          <div className='min-div'>
            <Hidden mdDown>
              {getMainIcon(item)}
            </Hidden>
            <Hidden mdUp>
              <h3 className='min-date'>{item.order_date ? formatDateObj(item.order_date).day : formatDateObj(item.reservation_date).day}</h3>
              <h4 className='min-month'>{item.order_date ? formatDateObj(item.order_date)?.monthShort?.toUpperCase() : formatDateObj(item.reservation_date)?.monthShort?.toUpperCase()}</h4>
            </Hidden>
          </div>
          <div className='info-div'>
            <h3 className='date-card'>{
              item.order_date ? formatDateMed(item.order_date)?.toUpperCase() : formatDateMed(item.reservation_date)?.toUpperCase()}</h3>
            <h1 className='title-card'>
              {getTitle(item)}
              <Arrow />
            </h1>
            {
              showDeskReservationReason &&
              item.type !== PARKING_RESERVATION &&
              <h3 className="reason-card">
                {reasonWorkplaceI18n(item.reason)}
              </h3>
            }
            <Divider className="hr-title" />
            {getForm(item)}
          </div>
        </CardContent>
        <div className='card-wrapper-footer'>
          {getCancelButton(item)}
          {getCheckinButton(item)}
        </div>
      </Card>
    )
  }, [getCheckinButton, getForm, getMainIcon, getTitle, onClickItem, showDeskReservationReason, showReason, getCancelButton])

  return (
    <div
      ref={refNode}
      className="CardWrapper">
      {enabledAccessControlQr && getQRCard()}
      {items.map(getCard)}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    askForRoomReservationNotes: state.profile.company.ask_for_room_reservation_notes,
    askForDeskReservationReason: state.profile.company.ask_for_desk_reservation_reason,
    askForDeskReservationEntryTime: state.profile.company.ask_for_desk_reservation_entry_time,
    askForParkingReservationReason: state.profile.company.ask_for_parking_reservation_reason,
    askForParkingReservationEntryTime: state.profile.company.ask_for_parking_reservation_entry_time,
    askForParkingReservationVehicle: state.profile.company.ask_for_parking_reservation_vehicle,
    enabledDeskReservationManualCheckin: state.profile.enabled_desk_reservations_manual_checkin,
    enabledParkingReservationManualCheckin: state.profile.enabled_parking_reservations_manual_checkin,
    enabledRoomReservationManualCheckin: state.profile.enabled_room_reservations_manual_checkin,
    workplaceEnabledSymptomAssessment: state.profile.company.workplace_enabled_symptom_assessment,
    showDeskReservationReason: state.profile.company.show_desk_reservation_reason,
    enabledMultiplesParkingReservations: state.profile.company.enabled_multiples_parking_reservations,
    cafeteriaMinTimeForCancelOrder: state.profile.company.cafeteria_min_time_for_cancel_order,
    workplaceCustomLabel: state.profile.company.workplace_custom_label,
    workplaceCustomIconIndex: state.profile.company.workplace_custom_icon_index,
    enabledWorkplaceCustomIcon: state.profile.company.enabled_workplace_custom_icon,
    enabledAccessControlQr: state.profile.company.enabled_access_control_qr
  }
}

export default connect(mapStateToProps)(CardWrapper)
