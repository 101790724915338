const ParkingDeskSvg = ({ className = '', style = {}, width = 24, height = 24 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      fill="none" xmlns="http://www.w3.org/2000/svg" ><g clip-path="url(#a)"><g clip-path="url(#b)"><path d="M9.5 2.5a.7.7 0 0 0-.8-.5H3.3c-.4 0-.7.2-.8.5l-1 2.8v3.9a.7.7 0 1 0 1.5 0V9h6v.3c0 .4.3.7.8.7.4 0 .7-.3.7-.8V5.3l-1-2.8Zm-6.3 5a.7.7 0 1 1 0-1.5.7.7 0 0 1 0 1.5Zm5.5 0a.7.7 0 1 1 0-1.5.7.7 0 0 1 0 1.5ZM2.5 5l.6-2c.1-.1.3-.3.5-.3h4.8c.2 0 .4.2.5.4L9.5 5h-7Z" fill="rgba(0, 0, 0, 0.54)" /></g><g clip-path="url(#c)"><path d="M15.8 14.7a1 1 0 1 1 1.4-1.4 1 1 0 0 1-1.4 1.4ZM15 20v-4.5h-1V20c0 1.4 1.1 2.5 2.5 2.5h3v-1h-3c-.8 0-1.5-.7-1.5-1.5Zm7 2-2.5-2.5h-1.8v-1.8c.8.5 1.9 1 2.8 1v-1c-.8 0-1.8-.5-2.3-1l-.7-.8a1 1 0 0 0-.4-.3l-.5-.1c-.6 0-1.1.5-1.1 1.1v2.9c0 .8.7 1.5 1.5 1.5h2.5l1.8 1.7.7-.7Z" fill="rgba(0, 0, 0, 0.54)" /></g><path d="M0 24 24 0" stroke="#8C8CA2" stroke-linecap="round" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath><clipPath id="b"><path fill="#fff" d="M0 0h12v12H0z" /></clipPath><clipPath id="c"><path fill="#fff" transform="translate(12 12)" d="M0 0h12v12H0z" /></clipPath></defs></svg >
  )
}

export default ParkingDeskSvg



