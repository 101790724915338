const ParkingRoomSvg = ({ className = '', style = {}, width = 24, height = 24 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}

      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
      <g clip-path="url(#a)"><g clip-path="url(#b)">
        <path fill="rgba(0, 0, 0, 0.54)" d="M9.5 2.5a.7.7 0 0 0-.8-.5H3.3c-.4 0-.7.2-.8.5l-1 2.8v3.9a.7.7 0 1 0 1.5 0V9h6v.3c0 .4.3.7.8.7.4 0 .7-.3.7-.8V5.3l-1-2.8Zm-6.3 5a.7.7 0 1 1 0-1.5.7.7 0 0 1 0 1.5Zm5.5 0a.7.7 0 1 1 0-1.5.7.7 0 0 1 0 1.5ZM2.5 5l.6-2c.1-.1.3-.3.5-.3h4.8c.2 0 .4.2.5.4L9.5 5h-7Z" /></g><path fill="rgba(0, 0, 0, 0.54)" d="M17.5 18.3c-.6 0-1.2-.6-1.2-1.3 0-.7.6-1.2 1.2-1.2s1.2.5 1.2 1.2-.6 1.3-1.2 1.3Zm-3.4 0c-.5 0-1-.4-1-1 0-.7.5-1.2 1-1.2.6 0 1 .5 1 1.1 0 .7-.4 1.2-1 1.2Zm6.8 0c-.6 0-1-.4-1-1 0-.7.4-1.2 1-1.2.5 0 1 .5 1 1.2 0 .6-.5 1-1 1ZM12.5 21c-.4 0-.5-.1-.5-.4 0-.8.8-1.8 2.1-1.8.6 0 1 .2 1.3.4-.6.5-1 1.4-.7 1.8h-2.2Zm10 0h-2.2c.3-.4 0-1.3-.7-1.8.3-.2.7-.4 1.3-.4 1.3 0 2.1 1 2.1 1.8 0 .3-.1.4-.5.4Zm-6.8 0c-.5 0-.7-.1-.7-.4 0-.7 1-1.8 2.5-1.8s2.5 1 2.5 1.8c0 .3-.2.4-.7.4h-3.6Z" /><path stroke="rgba(0, 0, 0, 0.54)" stroke-linecap="round" d="M0 24 24 0" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath><clipPath id="b"><path fill="#fff" d="M0 0h12v12H0z" /></clipPath>
      </defs>
    </svg >
  )
}

export default ParkingRoomSvg